.underline-dashed {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: .2em;
  text-decoration-color: currentColor;
  text-decoration-thickness: 1px;
}

[data-tooltip] {
  position: relative;
  border-bottom: 1px dashed #000;
  cursor: help
}

[data-tooltip]::after {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  content: attr(data-tooltip);
  left: calc(100% + .8em);
  top: calc(-30%);
  border-radius: 3px;
  background-color: black;
  color: white;
  z-index: 10;
  padding: 8px;
  width: 300px;
  transform: translateX(-20px);
  transition: all 150ms cubic-bezier(.25, .8, .25, 1);
}

[data-tooltip]:hover::after {
  opacity: 1;
  transform: translateX(0);
  transition-duration: 300ms;
}

p {
  @extend .lh-copy;
  @extend .mt0;
  @extend .mb3;
}

h1 {
  @extend .f2;
  @extend .mb3;
}

h2 {
  @extend .f3;
  @extend .mb3;
  @extend .mt4;
  @extend .bb;
  @extend .b--black-70;
  @extend .pb1;
  border-width: 0.50px;
}

h3 {
  @extend .f4;
  @extend .mb3;
  @extend .mt4;
  @extend .b--dashed;
  @extend .bb;
  @extend .b--black-70;
  @extend .pb1;
  @extend .bt-0;
  @extend .br-0;
  @extend .bl-0;
  border-bottom-width: 1px;
}

ol, ul {
  li {
    @extend .pb2;
    line-height: 1.5em;
  }
}

.gist-data {
  max-height: 500px;
}

body {
  @extend .w-100;
  @extend .avenir;
  @extend .black-70;
  @extend .bg-white;
  @extend .pa3;
  @extend .f4;
  @extend .fw4;

  nav {
    a {
      @extend .dib;
      @extend .link;
      @extend .dim;
      @extend .black-70;
      @extend .pt3;
      @extend .pb3;
      @extend .pa3-ns;
      @extend .fw4;
    }

    a.home {
      @extend .ttu;
      @extend .fw4;
      @extend .pt3-ns;
      @extend .pr3-ns;
      @extend .pb3-ns;
      @extend .pl0;
    }

    a.about {
      @extend .ttu;
      @extend .pt3-ns;
      @extend .pb3-ns;
      @extend .pl3-ns;
    }
  }
}